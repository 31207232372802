.breadcrumbs--short {
	&:after {
		right: 10px;
	}
	ul.breadcrumbs__list {
		display: table;
	}

	.breadcrumbs__item {
		display: table-cell;
	}
}
