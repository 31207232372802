.l-page {
	display: table;
	width: 100%;
	table-layout: fixed;

	&__sidebar {
		display: table-cell;
		vertical-align: top;
	}

	&__content {
		vertical-align: top;
		display: table-cell;
	}

	&__nav {
		display: table;
		width: 100%;
	}

	&__nav-breadcrumbs {
		display: table-cell;
		vertical-align: middle;
	}

	&__nav-controls {
		display: table-cell;
		vertical-align: middle;
		text-align: right;
	}

	.l-page__sidebar-section--adaptive-menu {
		display: none;
	}
}

@if ($responsive) {
	@include breakpoint(small) {
		.l-page {
			display: block;
		}

		.l-page__sidebar {
			display: block;
		}

		.l-page__content {
			display: block;
		}
	}
}
