.l-layout-wrapper--1 {
	.l-header-top-line {
		display: table;
		width: 100%;

		&__title {
			display: table-cell;
			vertical-align: middle;
		}

		&__tools {
			display: table-cell;
			vertical-align: middle;
			text-align: right;
		}
	}

	.l-header-top-nav {
		display: table;
		width: 100%;

		&__logo {
			display: table-cell;
		}

		&__menu {
			display: table-cell;
		}
	}

	.l-page {
		&__nav {
			table-layout: fixed;
		}

		&__nav-breadcrumbs {
			padding-right: 90px;
		}

		&__nav-controls {
			position: absolute;
			display: block;
			right: 0;
		}
	}

	.l-buttons-area {
		display: inline-table;

		&__item {
			display: table-cell;
			vertical-align: middle;
			margin: 0;
			@include rem(padding-left, 5px);
		}

		&__mobile-nav-panel {
			display: none;
		}
	}

	.l-footer {
		&__inner {
			display: table;
			width: 100%;
		}

		&__copyright {
			display: table-cell;
			vertical-align: top;
		}

		&__nav {
			display: table-cell;
			vertical-align: top;
		}
	}
}

@if ($responsive) {
	@include breakpoint(md) {
		.l-layout-wrapper--1 {
			.l-header-top-line__logo {
				display: table-cell;
			}

			.l-header-top-line__title {
				display: none;
			}

			.l-header-top-nav__logo {
				display: none;
			}

			.l-header-top-nav__menu {
				display: none;
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-buttons-area__mobile-nav-panel {
				display: table-cell;
			}

			.l-page,
			.l-page__sidebar,
			.l-page__content {
				display: block;
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page {
				&__nav {
					display: block;
				}

				&__nav-breadcrumbs {
					display: block;
					padding-right: 0;
				}

				&__nav-controls {
					display: block;
					position: relative;
				}
			}

			.l-footer {
				&__inner {
					display: block;
				}

				&__copyright {
					display: block;
				}

				&__nav {
					display: block;
					text-align: left;
				}
			}
		}
	}
}
