.b-appeal--variant-5 {
	.b-appeal__info {
		display: table;
	}

	.b-appeal__post {
		display: table-cell;
	}

	.b-appeal__person {
		display: table-cell;
	}
}

@if ($responsive) {
	@include breakpoint(xs) {
		.b-appeal--variant-5 {
			.b-appeal__post,
			.b-appeal__info,
			.b-appeal__person {
				display: block;
			}
		}
	}
}
