@charset "UTF-8";
.color-navy-blue {
  color: #0079c1; }

/*
	В этом файле собираются fallback'и для браузеров, не поддерживающих FlexBox. Путь берется относительно того, что
	этот файл лежит в темповой директории во время обработки, и подтягивает из компонентов, лежащих во временной
	директории, специальные файлы no-flexbox.
	Сам файл дергается из tzar-core.
*/
.b-download-center-section {
  text-align: left; }

.b-download-center-line {
  display: table;
  width: 100%; }
  .b-download-center-line__action {
    display: table-cell;
    vertical-align: middle;
    text-align: right; }
    .b-download-center-line__action .b-checkbox {
      float: right; }
  .b-download-center-line__content {
    display: table-cell;
    vertical-align: middle;
    text-align: left; }

.l-page {
  display: table;
  width: 100%;
  table-layout: fixed; }
  .l-page__sidebar {
    display: table-cell;
    vertical-align: top; }
  .l-page__content {
    vertical-align: top;
    display: table-cell; }
  .l-page__nav {
    display: table;
    width: 100%; }
  .l-page__nav-breadcrumbs {
    display: table-cell;
    vertical-align: middle; }
  .l-page__nav-controls {
    display: table-cell;
    vertical-align: middle;
    text-align: right; }
  .l-page .l-page__sidebar-section--adaptive-menu {
    display: none; }

.b-appeal--variant-1 {
  display: table; }
  .b-appeal--variant-1 .b-appeal__personal-attribute {
    display: table; }
  .b-appeal--variant-1 .b-appeal__info,
  .b-appeal--variant-1 .b-appeal__content,
  .b-appeal--variant-1 .b-appeal__name,
  .b-appeal--variant-1 .b-appeal__signature {
    display: table-cell;
    vertical-align: top; }

@media (max-width: 510px) {
  .b-appeal--variant-1 .b-appeal__info,
  .b-appeal--variant-1 .b-appeal__content {
    display: block; } }

.b-appeal--variant-4 .b-appeal__info {
  position: relative; }

.b-appeal--variant-4 .b-appeal__person {
  position: absolute;
  bottom: 0; }

.b-appeal--variant-4 .b-appeal__photo {
  margin-left: auto; }

@media (max-width: 510px) {
  .b-appeal--variant-4 .b-appeal__person {
    position: relative; } }

.b-appeal--variant-5 .b-appeal__info {
  display: table; }

.b-appeal--variant-5 .b-appeal__post {
  display: table-cell; }

.b-appeal--variant-5 .b-appeal__person {
  display: table-cell; }

@media (max-width: 510px) {
  .b-appeal--variant-5 .b-appeal__post,
  .b-appeal--variant-5 .b-appeal__info,
  .b-appeal--variant-5 .b-appeal__person {
    display: block; } }

.b-appeal--variant-6 .b-appeal__personal-attribute {
  display: table; }

.b-appeal--variant-6 .b-appeal__name {
  display: table-cell;
  vertical-align: top; }

.breadcrumbs--short:after {
  right: 10px; }

.breadcrumbs--short ul.breadcrumbs__list {
  display: table; }

.breadcrumbs--short .breadcrumbs__item {
  display: table-cell; }

.b-lang--multi-button {
  display: table; }
  .b-lang--multi-button .b-lang__item {
    display: table-cell; }

.l-layout-wrapper--1 .l-header-top-line {
  display: table;
  width: 100%; }
  .l-layout-wrapper--1 .l-header-top-line__title {
    display: table-cell;
    vertical-align: middle; }
  .l-layout-wrapper--1 .l-header-top-line__tools {
    display: table-cell;
    vertical-align: middle;
    text-align: right; }

.l-layout-wrapper--1 .l-header-top-nav {
  display: table;
  width: 100%; }
  .l-layout-wrapper--1 .l-header-top-nav__logo {
    display: table-cell; }
  .l-layout-wrapper--1 .l-header-top-nav__menu {
    display: table-cell; }

.l-layout-wrapper--1 .l-page__nav {
  table-layout: fixed; }

.l-layout-wrapper--1 .l-page__nav-breadcrumbs {
  padding-right: 90px; }

.l-layout-wrapper--1 .l-page__nav-controls {
  position: absolute;
  display: block;
  right: 0; }

.l-layout-wrapper--1 .l-buttons-area {
  display: inline-table; }
  .l-layout-wrapper--1 .l-buttons-area__item {
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    padding-left: 5px;
    padding-left: 0.3125rem; }
  .l-layout-wrapper--1 .l-buttons-area__mobile-nav-panel {
    display: none; }

.l-layout-wrapper--1 .l-footer__inner {
  display: table;
  width: 100%; }

.l-layout-wrapper--1 .l-footer__copyright {
  display: table-cell;
  vertical-align: top; }

.l-layout-wrapper--1 .l-footer__nav {
  display: table-cell;
  vertical-align: top; }

@media (max-width: 1054px) {
  .l-layout-wrapper--1 .l-header-top-line__logo {
    display: table-cell; }
  .l-layout-wrapper--1 .l-header-top-line__title {
    display: none; }
  .l-layout-wrapper--1 .l-header-top-nav__logo {
    display: none; }
  .l-layout-wrapper--1 .l-header-top-nav__menu {
    display: none; } }

@media (max-width: 798px) {
  .l-layout-wrapper--1 .l-buttons-area__mobile-nav-panel {
    display: table-cell; }
  .l-layout-wrapper--1 .l-page,
  .l-layout-wrapper--1 .l-page__sidebar,
  .l-layout-wrapper--1 .l-page__content {
    display: block; } }

@media (max-width: 510px) {
  .l-layout-wrapper--1 .l-page__nav {
    display: block; }
  .l-layout-wrapper--1 .l-page__nav-breadcrumbs {
    display: block;
    padding-right: 0; }
  .l-layout-wrapper--1 .l-page__nav-controls {
    display: block;
    position: relative; }
  .l-layout-wrapper--1 .l-footer__inner {
    display: block; }
  .l-layout-wrapper--1 .l-footer__copyright {
    display: block; }
  .l-layout-wrapper--1 .l-footer__nav {
    display: block;
    text-align: left; } }

.b-search.b-search--compact .b-search__container {
  display: table; }

.b-search.b-search--compact .b-search__wrapper-input,
.b-search.b-search--compact .b-search__button {
  display: table-cell; }

/*
	Стили, используемые для элементов из папки style
 */
/*
	link.scss
 */
.b-link-icon {
  display: table; }
  .b-link-icon img {
    display: block;
    width: 25px;
    vertical-align: middle; }
  .b-link-icon__icon {
    display: table-cell;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px; }
  .b-link-icon__text {
    display: table-cell;
    vertical-align: middle; }

.b-link-icon--right .b-link-icon__icon {
  padding-left: 5px;
  padding-right: 0; }

.b-link-icon--size-m .b-link-icon__icon {
  width: 30px; }
  .b-link-icon--size-m .b-link-icon__icon img {
    width: 30px; }

.b-link-icon--size-l .b-link-icon__icon {
  width: 40px; }
  .b-link-icon--size-l .b-link-icon__icon img {
    width: 40px; }
