.b-search.b-search--compact {
	.b-search__container {
		display: table;
	}

	.b-search__wrapper-input,
	.b-search__button {
		display: table-cell;
	}
}
