.b-appeal--variant-4 {
	.b-appeal {
		&__info {
			position: relative;
		}

		&__person {
			position: absolute;
			bottom: 0;
		}

		&__photo {
			margin-left: auto;
		}
	}
}

@if ($responsive) {
	@include breakpoint(xs) {
		.b-appeal--variant-4 {
			.b-appeal__person {
				position: relative;
			}
		}
	}
}
