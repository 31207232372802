.b-appeal--variant-6 {
	.b-appeal {
		&__personal-attribute {
			display: table;
		}

		&__name {
			display: table-cell;
			vertical-align: top;
		}
	}
}
