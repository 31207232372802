/*
	В этом файле собираются fallback'и для браузеров, не поддерживающих FlexBox. Путь берется относительно того, что
	этот файл лежит в темповой директории во время обработки, и подтягивает из компонентов, лежащих во временной
	директории, специальные файлы no-flexbox.
	Сам файл дергается из tzar-core.
*/

@import "../../components/**/no-flexbox.scss";
@import "../../components/**/.variant/**/no-flexbox.scss";

/*
	Стили, используемые для элементов из папки style
 */

/*
	link.scss
 */

.b-link-icon {
	display: table;

	img {
		display: block;
		width: 25px;
		vertical-align: middle;
	}

	&__icon {
		display: table-cell;
		vertical-align: middle;
		width: 25px;
		padding-right: 5px;
	}

	&__text {
		display: table-cell;
		vertical-align: middle;
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		padding-left: 5px;
		padding-right: 0;
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		width: 30px;

		img {
			width: 30px;
		}
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		width: 40px;

		img {
			width: 40px;
		}
	}
}
