.b-appeal--variant-1 {
	display: table;

	.b-appeal__personal-attribute {
		display: table;
	}

	.b-appeal__info,
	.b-appeal__content,
	.b-appeal__name,
	.b-appeal__signature {
		display: table-cell;
		vertical-align: top;
	}
}

@if ($responsive) {
	@include breakpoint(xs) {
		.b-appeal--variant-1 {
			.b-appeal__info,
			.b-appeal__content {
				display: block;
			}
		}
	}
}
