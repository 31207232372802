.b-download-center {
	
}

.b-download-center-section {
	text-align: left;
	
	&__title {
	
	}
	
	&__col-title {
	
	}
}

.b-download-center-line {
	display: table;
	width: 100%;
	
	&__action {
		display: table-cell;
		vertical-align: middle;
		text-align: right;
		
		.b-checkbox {
			float: right;
		}
	}
	
	
	&__content {
		display: table-cell;
		vertical-align: middle;
		text-align: left;
	}
}
